import { Metadata } from '@redwoodjs/web'

const StatusPage = () => {
  return (
    <>
      <Metadata title="Status" description="Status page" />

      <iframe
        src={'https://status.stafflink.com.au'}
        title={'Status'}
        height="100%"
        width="100%"
      ></iframe>
    </>
  )
}

export default StatusPage
